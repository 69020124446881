import type { Post } from "@/types/post";
import { urlFor } from "@/lib/utils/sanity/sanityHelpers.ts";
import Text from "@/components/atoms/text/Text.tsx";
import { H4 } from "@/components/atoms/text/Headings.tsx";
import {
  localDateShort,
  localDateTime,
} from "@/lib/utils/parsers/dateParsers.ts";

export interface PostCardProps {
  post: Post;
  onClick?: () => void;
  maxChars?: number;
  inGrid?: boolean;
}

const PostCard = ({
  inGrid = false,
  post,
  onClick,
  maxChars = 78,
}: PostCardProps) => {
  const image = urlFor(post?.mainImage)
    ?.width(inGrid ? 980 : 256)
    .url();

  return (
    <a href={post.slug.current} onClick={onClick && onClick}>
      <div
        className={
          "relative flex shadow hover:shadow-xl transition rounded h-full w-full"
        }
      >
        <div
          className={"flex flex-row md:flex-col md:w-full md:align-top gap-3 "}
        >
          {image && (
            <img
              loading={"lazy"}
              className={
                "rounded-l md:rounded-t md:rounded-l-none w-16 md:w-full md:h-44 h-full object-cover shadow-inner"
              }
              src={image}
              alt={post.title + " bilde"}
            />
          )}
          <div
            className={
              "absolute rounded bg-gradient-to-b from-transparent to-background h-6 bottom-0 md:bottom-4 w-full"
            }
          ></div>
          <div
            className={
              "py-2 pr-2 pl-0 md:px-4 md:py-0 md:mb-4 md:pb-4 w-full text-clip overflow-hidden flex flex-col gap-0"
            }
          >
            <H4>{post.title}</H4>
            <Text size={"xs"} variant={"subtle"}>
              {post.author?.name} | {localDateShort(post.publishedAt)}
            </Text>

            <Text className={"mt-2"} size={"sm"} variant={"subtle"}>
              {post.excerpt}
            </Text>
          </div>
        </div>
      </div>
    </a>
  );
};

export default PostCard;
